import createMqSwiper from 'features/swipers/createMqSwiper'

createMqSwiper({
	mq: '(max-width: 1200px)',
	obj: {
		container: '.blog .swiper',
		options: {
			slidesPerView: 1,
			spaceBetween: 20,
			observer: true,
			breakpoints: {
				450: {
					spaceBetween: 35,
				},
				800: {
					slidesPerView: 2
				}
			}
		}
	}
})
import { EffectFade, Pagination } from 'swiper'
import createMqSwiper from 'features/swipers/createMqSwiper'

createMqSwiper({
	mq: '(max-width: 1200px)',
	obj: {
		container: '.catalog__category-swiper',
		options: {
			pagination: { el: '.swiper-pagination' },
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			modules: [ EffectFade, Pagination ],
			on: {
				slideChange: (swiper) => {
					const activeCategory = swiper.slides[swiper.activeIndex] as HTMLElement
					const linkEl = activeCategory.querySelector<HTMLElement>('a[data-link]')
					if (linkEl) linkEl.click()
				},
			},
		},
	},
})